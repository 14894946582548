import React, { useContext, useEffect } from "react";
import { graphql, PageProps } from "gatsby";

import SbEditable from "storyblok-react";
import { useLanguageContext, LANGUAGE_ACTIONS } from "@/context";
import { useStoryblok } from "@/base/lib/storyblok";
import Layout from "@/components/layouts/Layout";
import CommonPostSection from "@/components/sections/CommonPostSection";
import StoryblokComponent from "@/base/utils/connectSbComponent";

interface PageData {
  layout: StoryblokStory;
}

export default function Post({
  pageContext,
  location,
  data,
}: PageProps<PageData, PostContext>) {
  const { lang, languages, story } = pageContext;
  const { layout } = data;
  const { dispatch } = useContext(useLanguageContext);
  const pageStory = useStoryblok<PostTemplate>(story, location);
  const { content } = pageStory;
  const { metadata } = content;

  const parsedLayout = layout && (JSON.parse(layout.content) as LayoutTemplate);
  const { header, footer } = parsedLayout || {};

  if (story == null) {
    return (
      <div>
        <h3>Editor page</h3>
      </div>
    );
  }

  useEffect(() => {
    dispatch({
      type: LANGUAGE_ACTIONS.setLanguage,
      payload: lang,
    });
    dispatch({
      type: LANGUAGE_ACTIONS.setLanguages,
      payload: languages,
    });
  }, []);

  return (
    <Layout
      header={header && header[0]}
      footer={footer && footer[0]}
      seo={{
        ...metadata,
        og_type: "article",
        og_image: metadata?.og_image || content?.post_hero?.image,
      }}
      siteName={story.name}
      pathname={location.pathname}
      hash={location.hash}
    >
      <CommonPostSection
        path={location.href}
        postContent={content}
        postId={pageStory.internalId}
      />
      {content.body &&
        content.body?.map((item) => {
          return (
            <SbEditable key={item._uid} content={item}>
              <StoryblokComponent blok={item} />
            </SbEditable>
          );
        })}
    </Layout>
  );
}

export const postQuery = graphql`
  query PostQuery($langRegex: String!) {
    layout: storyblokEntry(
      field_component: { eq: "common_layout" }
      full_slug: { regex: $langRegex }
    ) {
      content
    }
    # placeholder: file(relativePath: { eq: "../../assets/img/news.png" }) {
    #   childImageSharp: {

    #   }
    # }
  }
`;
